require('raphael/raphael.min');
require('morris.js/morris.min');

import '../src/bootstrap_overrides.scss';
import 'morris.js/morris.css';

import { Accounts } from '../src/accounts';

$(document).ready(function() {
    Accounts.setup();
})
