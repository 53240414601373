export class Accounts {
  static setup() {
    Accounts.show_or_hide_social_share_text();
    Accounts.characters_remaining();

    $('#account_alternate_social_share_image').change(() => Accounts.show_or_hide_social_share_text());
    const has_chart_values = chart => parseInt(chart.data("data").value) > 0;

    if ($("#users_chart").length) {
      Morris.Bar({
        horizontal: false,
        element: "users_chart",
        data: $("#users_chart").data("users"),
        xkey: "x_key",
        ykeys: ["sale", "delivery", "email", "sms"],
        labels: ["Sales Broadcast", "Delivery Unicast", "Emails Sent", "SMS Sent"],
        barColors: ["#0b62a4", "#7a92a3", "#edc240", "#ff0000"]});
    }

    if ($("#date_chart").length) {
      Morris.Line({
        element: "date_chart",
        data: $("#date_chart").data("data"),
        xkey: "x_key",
        ykeys: ["sale", "delivery", "email", "sms"],
        labels: ["Sales Broadcast", "Delivery Unicast", "Emails Sent", "SMS Sent"],
        barColors: ["#0b62a4", "#7a92a3", "#edc240", "#ff0000"]});
    }

    if ($("#events_vs_email_chart").length) {
      Morris.Donut({
        data: $("#events_vs_email_chart").data("data"),
        element: "events_vs_email_chart",
        colors: ["#72a83f", "#4f83d8"],
        formatter(value, data) { return value + '%'; }
      });
    }

    if ($("#email_shares_chart").length && has_chart_values($("#email_shares_chart"))) {
      Morris.Donut({
        data: $("#email_shares_chart").data("data"),
        element: "email_shares_chart",
        colors: ["#72a83f", "#4f83d8"],
        formatter(value, data) { return value + '%'; }
      });
    }

    if ($("#social_media_type_chart").length && has_chart_values($("#social_media_type_chart"))) {
      Morris.Donut({
        data: $("#social_media_type_chart").data("data"),
        element: "social_media_type_chart",
        colors: ["#72a83f", "#4f83d8"],
        formatter(value, data) { return value + '%'; }
      });
    }

    if ($("#events_and_shares_chart").length) {
      Morris.Bar({
        horizontal: false,
        element: "events_and_shares_chart",
        data: $("#events_and_shares_chart").data("data"),
        xkey: "date",
        ykeys: ["abn_live", "total_social"],
        labels: ["ABN Live Events", "Social Media Shares"],
        barColors: ["#3f71c8", "#5d9738"]});
    }

    if ($("#events_vs_shares_chart").length) {
      return Morris.Bar({
        horizontal: false,
        element: "events_vs_shares_chart",
        data: $("#events_vs_shares_chart").data("data"),
        xkey: "date",
        ykeys: ["ratio"],
        labels: ["ABN Live Events vs. Social Shares"],
        barColors: ["#3f71c8"],
        units: '%'
      });
    }
  }

  static show_or_hide_social_share_text () {
    if($('#account_alternate_social_share_image').prop('checked')) {
      return $('.account_alternate_social_share_text').show();
    } else {
      $('#account_alternate_social_share_text').val('');
      Accounts.update_remaining_text();
      return $('.account_alternate_social_share_text').hide();
    }
  }

  static characters_remaining() {
    $('.account_alternate_social_share_text').append('<div id="characters_remaining">200 characters remaining</div>');
    Accounts.update_remaining_text();

    $('#account_alternate_social_share_text').keyup(() => Accounts.update_remaining_text());
  }

  static update_remaining_text() {
    const share_text = $('#account_alternate_social_share_text');
    const max_length = share_text.prop('maxlength');
    const share_text_length = share_text.val() ? share_text.val().length : 0;
    const remaining_characters = max_length - share_text_length;
    return $('#characters_remaining').html(remaining_characters + " characters remaining");
  }
}
